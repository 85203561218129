import { ErrorState } from '../../ui/error/types'
import {
  AppRequest,
  executeAppRequest,
  request
} from '../../utils/request_handler'
import { SERVICE_ROOT_URL } from '../api/config'
import { SessionTypeValues } from '../sessions/data_types'
import {
  SubSessionType,
  SubSessionTypeValues
} from '../sessions/sub_sessions/data_types'
import { SportTypeValues } from '../sports/data_types'
import { SeatConfiguration } from './types'

export function hardwareService(
  handleError: (error: ErrorState) => void,
  handleSuccess: (response) => void,
  { tagIds, type }
) {
  const error = (error, errorType) => {
    return handleError({
      message:
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to ${type}. Request failed. Check log for more detail.`
    })
  }
  const options = {
    url: type,
    data: { ids: tagIds },
    method: 'post',
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return request(options, handleSuccess, error)
}

export function enableRSSI(
  handleError: (error: ErrorState) => void,
  handleSuccess: (response) => void
) {
  const error = (error, errorType) => {
    return handleError({
      message:
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to enable RSSI. Request failed. Check log for more detail.`
    })
  }
  const options = {
    url: 'enableRSSI',
    data: {},
    method: 'post',
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return request(options, handleSuccess, error)
}

export function disableRSSI(
  handleError: (error: ErrorState) => void,
  handleSuccess: (response) => void
) {
  const error = (error, errorType) => {
    return handleError({
      message:
        error.response && error.response.data && error.response.data[errorType]
          ? error.response.data[errorType]
          : `Failed to disable RSSI. Request failed. Check log for more detail.`
    })
  }
  const options = {
    url: 'disableRSSI',
    data: {},
    method: 'post',
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return request(options, handleSuccess, error)
}

// Sleep all tags and balls except for the ones in the pitch
export type SleepAllButOnFieldResponseBody = number[]

export const sleepAllButOnField: AppRequest<
  null,
  SleepAllButOnFieldResponseBody
> = async () => {
  const options = {
    url: 'greylist/offpitch',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Get / update seat swapping algorithm state //
export type SeatSwappingStateResponseBody = {
  Enabled: true
  DebugInfo: any
}

export const getSeatSwappingAlgoState: AppRequest<
  null,
  SeatSwappingStateResponseBody
> = async () => {
  const options = {
    url: 'seatSwap',
    method: 'get' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

export const updateSeatSwappingAlgoState: AppRequest<
  boolean,
  SeatSwappingStateResponseBody
> = async () => {
  const options = {
    url: 'seatSwap',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}
// ===================================/

// Reset seat swapping //
export const resetSeatSwapping: AppRequest<
  null,
  SeatSwappingStateResponseBody
> = async () => {
  const options = {
    url: 'seatSwap/reset',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Get / update seat configuration //
export const getSeatConfiguration: AppRequest<
  null,
  SeatConfiguration
> = async () => {
  const options = {
    url: 'seatConfiguration',
    method: 'get' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

export type UpdateSeatConfigurationRequestBody = {
  sportType: SportTypeValues
  sessionType: SessionTypeValues
  sessionSubType: SubSessionTypeValues
}

export const updateSeatConfiguration: AppRequest<
  UpdateSeatConfigurationRequestBody,
  SeatConfiguration
> = async (data) => {
  const options = {
    url: 'seatConfiguration',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true,
    data: data
  }
  return executeAppRequest(options)
}

// Demote all tags and balls off pitch //
export type DemoteAllOffPitchResponseBody = number[]

export const demoteAllOffPitch: AppRequest<
  null,
  DemoteAllOffPitchResponseBody
> = async () => {
  const options = {
    url: 'power/demote/offPitch',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}

// Promote all tags and balls on pitch //
export type PromoteAllOnPitchResponseBody = number[]

export const promoteAllOnPitch: AppRequest<
  null,
  PromoteAllOnPitchResponseBody
> = async () => {
  const options = {
    url: 'power/promote/onPitch',
    method: 'post' as const,
    baseURL: SERVICE_ROOT_URL,
    withCredentials: true
  }
  return executeAppRequest(options)
}
