import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  MutableRefObject
} from 'react'
import styles from './DrillZone.module.scss'
import { useAppDispatch } from '../../../../store/hooks'
import { updateExistingDrill } from '../../../../metrics_server/drills/thunks'
import {
  useDrills,
  useSelectedFormattedDrill
} from '../../../../metrics_server/drills/hooks'
import { useSelectedFormattedSession } from '../../../../metrics_server/sessions/hooks'
import { debounce } from 'lodash'
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import Checkbox from '../../../Forms/Inputs/Checkbox/Checkbox'

export function StrackDrillZone({ strack, active }) {
  const {
    session,
    field,
    getCanvasCoordinate,
    pitchPlaneCanvasCoordinates,
    scale
  } = strack

  const dispatch = useAppDispatch()
  const { live, sport } = useSelectedFormattedSession()
  const {
    drills,
    drills: { selectedDrill, selectedDrillId }
  } = useDrills()

  const formattedDrill = useSelectedFormattedDrill()

  const [drillPosition, setDrillPosition] = useState({ x: 0, y: 0 })
  const [drillZoneSize, setDrillZoneSize] = useState({
    width: field.width * scale,
    height: field.height * scale
  })

  const [dragging, setDragging] = useState(false)
  const [resizing, setResizing] = useState(false)
  const [startPos, setStartPos] = useState({ x: 0, y: 0 })

  const goalOrPostDimensions = sport.props.pitch?.drillsGoalsOrPosts?.enabled
    ? {
        width: sport.props.pitch.drillsGoalsOrPosts.dimensions.width * scale,
        height: sport.props.pitch.drillsGoalsOrPosts.dimensions.height * scale,
        depth: sport.props.pitch.drillsGoalsOrPosts.dimensions.depth * scale
      }
    : null

  // Function to calculate initial position and size of the drill zone
  const updateDrillZone = useCallback(() => {
    let newDrillPosition = getCanvasCoordinate(
      scale,
      -field.width / 2,
      field.height,
      true
    )
    newDrillPosition = {
      x: newDrillPosition.scaleX,
      y: newDrillPosition.scaleY
    }

    let newDrillZoneSize = {
      width: field.width * scale,
      height: field.height * scale
    }

    const selectedDrill = drills.drills.find(
      (drill) => drill.id === selectedDrillId
    )
    if (selectedDrill && selectedDrill.extraInfo.region) {
      const { P1, P2, P3 } = selectedDrill.extraInfo.region

      if ([P1, P2].every((point) => point.x !== 0 || point.y !== 0)) {
        const scaledP1 = getCanvasCoordinate(scale, P1.x, P1.y, true)
        const scaledP2 = getCanvasCoordinate(scale, P2.x, P2.y, true)
        const scaledP3 = getCanvasCoordinate(scale, P3.x, P3.y, true)
        newDrillPosition = { x: scaledP1.scaleX, y: scaledP1.scaleY }
        const width = scaledP2.scaleX - scaledP1.scaleX
        const height = scaledP3.scaleY - scaledP1.scaleY
        newDrillZoneSize = { width, height }
      }
    }

    setDrillPosition(newDrillPosition)
    setDrillZoneSize(newDrillZoneSize)
  }, [
    getCanvasCoordinate,
    scale,
    field.width,
    field.height,
    drills.drills,
    selectedDrillId
  ])

  useEffect(() => {
    updateDrillZone()
  }, [updateDrillZone])

  const onMouseDownDrag = useCallback(
    (e) => {
      const initialX = e.clientX - drillPosition.x
      const initialY = e.clientY - drillPosition.y

      setDragging(true)
      setStartPos({
        x: initialX,
        y: initialY
      })
    },
    [drillPosition]
  )

  const onMouseDownResize = useCallback((e) => {
    e.stopPropagation()
    setResizing(true)
    setStartPos({ x: e.clientX, y: e.clientY })
  }, [])

  const onMouseMove = useCallback(
    (e) => {
      if (dragging) {
        setDrillPosition({
          x: e.clientX - startPos.x,
          y: e.clientY - startPos.y
        })
      }
      if (resizing) {
        setDrillZoneSize((prevSize) => ({
          width: Math.max(20, prevSize.width + (e.clientX - startPos.x)),
          height: Math.max(20, prevSize.height + (e.clientY - startPos.y))
        }))
        setStartPos({ x: e.clientX, y: e.clientY })
      }
    },
    [dragging, resizing, startPos]
  )

  const getRegion = useCallback(() => {
    let scaledDrillPosition = strack.getPitchCoordinate(
      scale,
      drillPosition.x,
      drillPosition.y
    )

    scaledDrillPosition = {
      x: scaledDrillPosition.pitchX,
      y: scaledDrillPosition.pitchY
    }

    const scaledDrillZoneSize = {
      width: drillZoneSize.width / scale,
      height: drillZoneSize.height / scale
    }

    const scaledGoalOrPostDimensions = goalOrPostDimensions && {
      width: goalOrPostDimensions.width / scale,
      height: goalOrPostDimensions.height / scale
    }

    // Calculate goal positions
    const centerY = scaledDrillPosition.y - scaledDrillZoneSize.height / 2

    const updatedRegion = {
      P1: { x: scaledDrillPosition.x, y: scaledDrillPosition.y, z: 0 },
      P2: {
        x: scaledDrillPosition.x + scaledDrillZoneSize.width,
        y: scaledDrillPosition.y,
        z: 0
      },
      P3: {
        x: scaledDrillPosition.x + scaledDrillZoneSize.width,
        y: scaledDrillPosition.y - scaledDrillZoneSize.height,
        z: 0
      },
      P4: {
        x: scaledDrillPosition.x,
        y: scaledDrillPosition.y - scaledDrillZoneSize.height,
        z: 0
      },
      P5: {
        x: scaledGoalOrPostDimensions ? scaledDrillPosition.x : 0,
        y: scaledGoalOrPostDimensions
          ? centerY + scaledGoalOrPostDimensions.width / 2
          : 0,
        z: scaledGoalOrPostDimensions ? scaledGoalOrPostDimensions.height : 0
      },
      P6: {
        x: scaledGoalOrPostDimensions
          ? scaledDrillPosition.x + scaledDrillZoneSize.width
          : 0,
        y: scaledGoalOrPostDimensions
          ? centerY + scaledGoalOrPostDimensions.width / 2
          : 0,
        z: scaledGoalOrPostDimensions ? scaledGoalOrPostDimensions.height : 0
      },
      P7: {
        x: scaledGoalOrPostDimensions ? scaledDrillPosition.x : 0,
        y: scaledGoalOrPostDimensions
          ? centerY - scaledGoalOrPostDimensions.width / 2
          : 0,
        z: scaledGoalOrPostDimensions ? scaledGoalOrPostDimensions.height : 0
      },
      P8: {
        x: scaledGoalOrPostDimensions
          ? scaledDrillPosition.x + scaledDrillZoneSize.width
          : 0,
        y: scaledGoalOrPostDimensions
          ? centerY - scaledGoalOrPostDimensions.width / 2
          : 0,
        z: scaledGoalOrPostDimensions ? scaledGoalOrPostDimensions.height : 0
      }
    }
    return updatedRegion
  }, [
    drillPosition.x,
    drillPosition.y,
    drillZoneSize.height,
    drillZoneSize.width,
    goalOrPostDimensions?.height,
    goalOrPostDimensions?.width,
    scale,
    strack
  ])

  const onMouseUp = useCallback(() => {
    if (dragging || resizing) {
      setDragging(false)
      setResizing(false)

      const updatedRegion = getRegion()

      if (selectedDrillId) {
        dispatch(
          updateExistingDrill({
            ...selectedDrill,
            sessionId: session.id,
            extraInfo: { ...selectedDrill.extraInfo, region: updatedRegion }
          })
        )
      }
    }
  }, [
    dragging,
    resizing,
    drillZoneSize.height,
    drillZoneSize.width,
    goalOrPostDimensions?.height,
    goalOrPostDimensions?.width,
    drillPosition.x,
    drillPosition.y,
    formattedDrill?.bibDirection,
    selectedDrillId,
    dispatch,
    selectedDrill,
    session.id,
    scale,
    strack
  ])

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [onMouseMove, onMouseUp])

  const regionRef: MutableRefObject<any> = useRef()
  regionRef.current = { getRegion, selectedDrillId }

  const debouncedUpdateDrill = useRef(
    debounce(() => {
      // Use regionRef.current to access the latest state
      if (regionRef.current.selectedDrillId) {
        const updatedRegion = regionRef.current.getRegion()
        dispatch(
          updateExistingDrill({
            ...selectedDrill,
            sessionId: session.id,
            extraInfo: { ...selectedDrill.extraInfo, region: updatedRegion }
          })
        )
      }
    }, 1000)
  ).current

  useEffect(() => {
    const handleArrowKeyPress = (e) => {
      if (!active) return

      if (!live || !selectedDrillId || formattedDrill.isStarted) return

      let keyHandled = false
      const moveStep = 5
      if (e.shiftKey) {
        // Handling zone resizing with Shift + Arrow Keys
        const newSize = { ...drillZoneSize }
        switch (e.key) {
          case 'ArrowUp':
            newSize.height = Math.max(20, newSize.height - moveStep)
            keyHandled = true
            break
          case 'ArrowDown':
            newSize.height += moveStep
            keyHandled = true
            break
          case 'ArrowLeft':
            newSize.width = Math.max(20, newSize.width - moveStep)
            keyHandled = true
            break
          case 'ArrowRight':
            newSize.width += moveStep
            keyHandled = true
            break
          default:
            return
        }
        if (keyHandled) {
          setDrillZoneSize(newSize)
        }
      } else {
        // Handling zone movement with Arrow Keys
        const newPosition = { ...drillPosition }
        switch (e.key) {
          case 'ArrowUp':
            newPosition.y -= moveStep
            keyHandled = true
            break
          case 'ArrowDown':
            newPosition.y += moveStep
            keyHandled = true
            break
          case 'ArrowLeft':
            newPosition.x -= moveStep
            keyHandled = true
            break
          case 'ArrowRight':
            newPosition.x += moveStep
            keyHandled = true
            break

          default:
            return
        }
        if (keyHandled) {
          setDrillPosition(newPosition)
        }
      }
      if (keyHandled) {
        debouncedUpdateDrill()
      }
    }

    document.addEventListener('keydown', handleArrowKeyPress)
    return () => document.removeEventListener('keydown', handleArrowKeyPress)
  }, [
    live,
    selectedDrillId,
    drillPosition,
    drillZoneSize,
    dispatch,
    selectedDrill,
    session.id,
    getRegion,
    debouncedUpdateDrill,
    active
  ])

  const handleDirectionChange = (index, bibId) => {
    if (!live || formattedDrill.isStarted) return

    // Set new direction
    const newBibTeamDirection = [
      ...formattedDrill.rawData.extraInfo.bibTeamDirection
    ]
    newBibTeamDirection[index] = parseInt(bibId)

    // If the other direction is the same bib change it
    const otherIndex = index === 0 ? 1 : 0
    if (newBibTeamDirection[otherIndex] === parseInt(bibId)) {
      newBibTeamDirection[otherIndex] =
        formattedDrill.rawData.extraInfo.bibTeamDirection[index]
    }

    // Update the drill with the new directions
    dispatch(
      updateExistingDrill({
        ...formattedDrill.rawData,
        extraInfo: {
          ...formattedDrill.rawData.extraInfo,
          bibTeamDirection: newBibTeamDirection
        }
      })
    )
  }

  if (!selectedDrillId) {
    return null
  }

  return (
    <div className={styles.drillZoneContainer}>
      <div
        style={{
          width: `${drillZoneSize.width}px`,
          height: `${drillZoneSize.height}px`,
          top: `${drillPosition.y}px`,
          left: `${drillPosition.x}px`,
          position: 'relative',
          cursor: !live || formattedDrill.isStarted ? 'not-allowed' : 'grab'
        }}
        className={styles.drillZone}
        onMouseDown={
          !live || formattedDrill.isStarted ? undefined : onMouseDownDrag
        }
      >
        {sport.props.pitch?.drillsGoalsOrPosts?.enabled &&
          formattedDrill.bibDirection && (
            <>
              <div
                className={styles.goal}
                style={{
                  right: `100%`,
                  width: `${goalOrPostDimensions.depth}px`,
                  height: `${goalOrPostDimensions.width}px`,
                  borderRadius: '5px 0 0 5px'
                }}
              ></div>
              <div
                className={styles.goal}
                style={{
                  left: `100%`,
                  width: `${goalOrPostDimensions.depth}px`,
                  height: `${goalOrPostDimensions.width}px`,
                  borderRadius: '5px 5px 0 0'
                }}
              ></div>
            </>
          )}
        <div
          className={styles.resizeHandle}
          onMouseDown={
            !live || formattedDrill.isStarted ? undefined : onMouseDownResize
          }
        ></div>
      </div>
      {sport.props.pitch?.drillsGoalsOrPosts?.enabled && (
        <div
          style={{
            width: `${drillZoneSize.width}px`,
            height: '30px',
            top: `${drillPosition.y - 40}px`,
            left: `${drillPosition.x}px`,
            position: 'absolute',
            zIndex: 100000,
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'auto'
          }}
        >
          <Checkbox
            onClicked={(value) =>
              dispatch(
                updateExistingDrill({
                  ...formattedDrill.rawData,
                  extraInfo: {
                    ...formattedDrill.rawData.extraInfo,
                    bibTeamDirection: value ? [2, 3] : null
                  }
                })
              )
            }
            label={'Enable goals'}
            checked={!!formattedDrill.bibDirection}
            textColour='white'
            disabled={!live || formattedDrill.isStarted}
          />
        </div>
      )}
      {formattedDrill.bibDirection && (
        <div
          style={{
            width: `${
              pitchPlaneCanvasCoordinates.right -
              pitchPlaneCanvasCoordinates.left
            }px`,
            height: '30px',
            padding: '0 10px',
            boxSizing: 'border-box',
            top: `${pitchPlaneCanvasCoordinates.bottom + 10}px`,
            left: `${pitchPlaneCanvasCoordinates.left}px`,
            position: 'absolute',
            zIndex: 100000,
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '2px'
              }}
            >
              <select
                value={formattedDrill.bibDirection[0].id}
                onChange={(e) => handleDirectionChange(0, e.target.value)}
                disabled={!live || formattedDrill.isStarted}
                className={styles.bibSelect}
              >
                {formattedDrill.bibs.options
                  .filter((b) => b.value !== 0)
                  .map((bib) => (
                    <option key={bib.value} value={bib.value}>
                      {formattedDrill.bibs.map[bib.value].name}
                    </option>
                  ))}
              </select>
              <ArrowCircleRightIcon
                sx={{
                  color: formattedDrill.bibDirection[0].colour,
                  fontSize: '30px'
                }}
              />
            </div>
          </div>

          {/* Switch side button - hidden when drill has started */}
          {formattedDrill.isPending && (
            <div
              style={{
                width: `30px`,
                height: '30px',
                zIndex: 100000,
                pointerEvents: 'auto',
                cursor: 'pointer'
              }}
              onClick={() => {
                // Switch the bib team direction //
                const newBibTeamDirection =
                  formattedDrill.rawData.extraInfo.bibTeamDirection
                    .slice()
                    .reverse()

                dispatch(
                  updateExistingDrill({
                    ...formattedDrill.rawData,
                    extraInfo: {
                      ...formattedDrill.rawData.extraInfo,
                      bibTeamDirection: newBibTeamDirection
                    }
                  })
                )
              }}
            >
              <SwapHorizontalCircleIcon
                sx={{ color: 'white', fontSize: '30px' }}
              />
            </div>
          )}

          <div
            style={{
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '2px'
              }}
            >
              <ArrowCircleLeftIcon
                sx={{
                  color: formattedDrill.bibDirection[1].colour,
                  fontSize: '30px'
                }}
              />
              <select
                value={formattedDrill.bibDirection[1].id}
                onChange={(e) => handleDirectionChange(1, e.target.value)}
                disabled={!live || formattedDrill.isStarted}
                className={styles.bibSelect}
              >
                {formattedDrill.bibs.options
                  .filter((b) => b.value !== 0)
                  .map((bib) => (
                    <option key={bib.value} value={bib.value}>
                      {formattedDrill.bibs.map[bib.value].name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
