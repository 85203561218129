import { Sport, SportTypeValues, isSportType, sportTypes } from './data_types'

export const getSport = (sportType: SportTypeValues) => {
  return sportTypes.getTypeByValue(sportType)
}

export function isRugby(
  sport: Sport
): sport is
  | typeof sportTypes.items.rugbyUnion
  | typeof sportTypes.items.rugbyLeague {
  return isSportType.rugbyUnion(sport) || isSportType.rugbyLeague(sport)
}

export function isRugbyUnion(
  sport: Sport
): sport is typeof sportTypes.items.rugbyUnion {
  return isSportType.rugbyUnion(sport)
}
