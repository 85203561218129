import { TableRow } from '../../../components/Table/Table.types'
import { FormattedSession } from '../../sessions/types'
import { FlightData, RawFlightEventData } from './types'
import { FlightFilters } from '../filter'
import { flightEventTypes } from './data_types'
import { FormattedEventData } from '../types'
import { eventTypes } from '../data_types'
import { UnitSystem } from '../../units/types'
import { FlightEventSubTypeValues } from './subTypes/data_types'
import { isSportType } from '../../sports/data_types'
import { formatMetrics } from '../../metrics/functions'
import * as Sentry from '@sentry/react'

export const getMostRecentKick = (
  flights: FlightData[]
): FlightData | undefined => {
  const kicks = flights.filter(isKick)

  if (kicks.length === 0) return undefined

  return kicks.reduce((p, v) => {
    return p.startTime > v.startTime ? p : v
  })
}

export function filterFlights(
  flights,
  {
    team,
    player,
    type,
    kickTypes,
    passTypes,
    madeTouch,
    forward
  }: FlightFilters,
  sport?
) {
  let filteredFlights = [...flights]

  if (type) {
    filteredFlights = filteredFlights
      .filter((x) => {
        if (team.value === 'All' || !team) return true
        return team.value === x.teamID
      })
      .filter((x) => {
        if (type.value === 'All') return true
        return type.value === x.type
      })
      .filter((x) => {
        if (type.value === flightEventTypes.items.kick.value) {
          if (!kickTypes.value) return true
          if (kickTypes.value.length === 0) return true
          return kickTypes.value.some(
            (kick: FlightEventSubTypeValues | 'All') => kick === x.subType
          )
        }

        return true
      })
      .filter((x) => {
        if (type.value === flightEventTypes.items.pass.value) {
          if (!passTypes.value) return true
          if (passTypes.value.length === 0) return true
          return passTypes.value.some(
            (pass: FlightEventSubTypeValues | 'All') => pass === x.subType
          )
        }
        return true
      })
      .filter((x) => {
        if (forward) {
          if (forward.value === 'All') return true
          if (forward.value === 'forward') {
            return x.forward
          }
          if (forward.value === 'normal') {
            return !x.forward
          }
        }
        return true
      })
      .filter((x) => {
        const isSoccer = isSportType.soccer(sport)
        return x.hangTime < (isSoccer ? 12 : 6)
      })
      .filter((x) => {
        if (
          !player ||
          player.value.length === 0 ||
          player.value.includes('All')
        )
          return true
        return player.value.includes(x.fromPlayerId)
      })
      .filter((x) => {
        if (madeTouch.value === false) return true // If the filter is set to 'No', don't filter out any flights
        return x.madeTouch === madeTouch.value // If the filter is set to 'Yes', only include flights where madeTouch is true
      })
  }
  return filteredFlights
}

// Get latest kick
export const getLatestKick = (eventsArray: RawFlightEventData[]) => {
  let latestFlight: RawFlightEventData
  if (eventsArray.length > 0) {
    const orderedFlights = eventsArray.sort((a, b) => dec(a, b, 'startTime'))
    latestFlight = orderedFlights.find(
      (flight) => flight.id && flight.type === 'Kick' && !flight.ignore
    )
  }
  return latestFlight
}

// TODO: add to utils
function dec(a: TableRow, b: TableRow, key: string): number {
  if (a[key] < b[key]) return 1
  if (a[key] > b[key]) return -1
  return 0
}

// update flight object with touch metrics
export const updateFlightWithBouncedToTouch = (flight: FlightData) => {
  const allowableTimeForStraightOutDetection = 0.058

  const madeTouch = !!flight.timeAtTouch

  if (
    !!flight.timeAtTouch &&
    flight.type === flightEventTypes.items.kick.value
  ) {
    flight.madeTouch = madeTouch
    if (parseFloat(flight.timeAtTouch) > flight.timeEnd) {
      const timeOffsetFromEndOfFlight =
        parseFloat(flight.timeAtTouch) - flight.timeEnd
      if (timeOffsetFromEndOfFlight > allowableTimeForStraightOutDetection) {
        flight.bouncedToTouch = true
        flight.bounceTouchTime = timeOffsetFromEndOfFlight
      }
    } else {
      flight.bouncedToTouch = false
    }
    return flight
  }

  return flight
}

export const isKick = (flight: FlightData) => {
  return flight.type === flightEventTypes.items.kick.value
}

export const isPass = (flight: FlightData) => {
  return flight.type === flightEventTypes.items.pass.value
}

export const generateFormattedFlightData = (
  eventType: typeof eventTypes.items.flight,
  flight: RawFlightEventData,
  formattedSession: FormattedSession,
  unitSystem?: UnitSystem
): FormattedEventData => {
  const { flightTypes, teams, players, startTime } = formattedSession
  const sessionStartTime = (flight.startTime - startTime.unixSeconds) * 1000
  try {
    const team = teams?.map[flight.teamID]
    const player = players?.[team ? team.id : 'all'].map[flight.fromPlayerId]
    const operator = flight.operatorNotes
      ? {
          id: flight.operatorNotes.id,
          notes: flight.operatorNotes.notes,
          highlight: flight.operatorNotes.highlight,
          matchTime: flight.operatorNotes.matchTime
        }
      : null
    const ignore = flight.ignore

    const flightType = flightTypes?.getTypeByValue(flight.type)
    const flightSubType =
      flightType.props?.types && !flightType.props.types.isEmpty()
        ? flightType.props.types.getTypeByValue(flight.subType)
        : null

    const mainType =
      flightSubType &&
      !flightSubType.isUnknown() &&
      flightSubType.key !== 'unclassified'
        ? flightSubType
        : flightType

    // Outcome
    const subTypeOutcomeTypes = flightSubType
      ? flightSubType?.props?.outcomeTypes
      : null
    const selectedOutcomeType = subTypeOutcomeTypes
      ? subTypeOutcomeTypes?.getTypeByValue(flight?.outcome)
      : flightType?.props?.outcomeTypes?.getTypeByValue(flight?.outcome)
    const outcomeTypes = subTypeOutcomeTypes
      ? subTypeOutcomeTypes
      : flightType?.props?.outcomeTypes

    const outcome = !outcomeTypes?.isEmpty()
      ? {
          selected: selectedOutcomeType,
          options: outcomeTypes?.settableOptions
        }
      : null

    // Metrics
    const metrics = formatMetrics(
      mainType.props.metricTypes,
      flight,
      formattedSession,
      unitSystem
    )

    // Features //
    const features =
      flightSubType && !flightSubType.isUnknown()
        ? flightSubType?.props.features
        : flightType.props.features

    return {
      id: flight.id,
      rawData: flight,
      ballSerial:
        formattedSession.playersSessions?.byHardwareId?.map[flight.ballId]
          ?.hardware?.serial,
      sessionId: flight.event.sessionId,
      sessionStartTime: sessionStartTime,
      startTime: flight.event.startTime,
      endTime: flight.event.endTime,
      ignore: ignore,
      eventType: eventType.value,
      typeName: mainType.name,
      type: {
        selected: flightType,
        options: flightTypes?.options
      },
      subType: !flightType?.props?.types?.isEmpty()
        ? {
            selected: flightType.props.types?.getTypeByValue(flight.subType),
            options: flightType.props.types?.options
          }
        : null,
      operator: operator,
      team: {
        selected: team,
        options: teams?.optionsWithNull
      },
      player: {
        selected: player,
        options: players?.[team ? team.id : 'all'].optionsWithNull
      },
      metrics: metrics,
      outcome: outcome,

      compareTagPosition: {
        label: 'X Distance to touch',
        ...flight.positionExitedPitch
      },

      features
    }
  } catch (e) {
    const er = `flight ${flight.id} failed to render: ${JSON.stringify(
      flight
    )}\n${e}`
    console.error(er)
    Sentry.captureException(er)
    return {
      hasFailedToRender: e,
      id: flight.id,
      rawData: flight,
      sessionId: flight.event.sessionId,
      sessionStartTime: sessionStartTime,
      startTime: flight.event.startTime,
      endTime: flight.event.endTime,
      ignore: flight.ignore,
      metrics: {},
      compareTagPosition: { label: '', x: 0, y: 0, z: 0 },
      eventType: undefined,
      features: undefined,
      player: { options: undefined, selected: undefined },
      subType: null,
      team: { options: undefined, selected: undefined },
      type: undefined,
      typeName: '',
      ballSerial:
        formattedSession?.playersSessions?.byHardwareId?.map[flight.ballId]
          ?.hardware?.serial
    }
  }
}
