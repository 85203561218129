import React from 'react'
import { Checkbox, Grid } from '@mui/material'
import { SearchableDropdown } from '../SearchableDropdown/SearchableDropdown'
import BasicSelect from '../Material/Select'
import styles from './EventFilters.module.scss'
import { eventTypes } from '../../metrics_server/events/data_types'
import { flightEventTypes } from '../../metrics_server/events/flight/data_types'
import { EventsFilter, FlightFilters } from '../../metrics_server/events/filter'
import { useSelectedFormattedSession } from '../../metrics_server/sessions/hooks'
import { isSportType } from '../../metrics_server/sports/data_types'

export interface GenericFilterType {
  value: string | number
  name: string
}

export interface EventFiltersProps {
  filters: EventsFilter
}

export function EventFiltersForm({ filters }: EventFiltersProps) {
  const {
    flightsFilter,
    eventsFilter,
    aussieRulesEventsFilter,
    gameEventsFilter
  } = filters

  const { sport } = useSelectedFormattedSession()
  const isSoccer = isSportType.soccer(sport)

  return (
    <div className={styles.controls}>
      <Grid container rowSpacing={0} columnSpacing={1}>
        {/* <h5>Filter</h5> */}
        {/* <div style={{fontSize: '13px'}}>Team:</div>
            <div className="smallSelectContainer">
              <Select className="small-select" options={teamOptions} input={{name: 'teamFilter', value: teamFilter}} updateValue={this.updateFilterValue}/>
            </div> */}
        <Grid item xs={6}>
          <BasicSelect
            variant='standard'
            selected={eventsFilter.filters.type.value}
            onChange={(value) => eventsFilter.updateFilterValue('type', value)}
            label='Select Event Type'
            options={eventsFilter.filters.type.options}
            size={'small'}
            fontSize={12}
            padding={0}
            labelMarginTop={8}
            labelShrink={true}
          />
        </Grid>
        {eventTypes.isType('flight', eventsFilter.filters.type.value) && (
          <>
            <Grid item xs={6}>
              <BasicSelect
                variant='standard'
                selected={flightsFilter.filters.type.value}
                onChange={(value) =>
                  flightsFilter.updateFilterValue('type', value)
                }
                label='Select Flight Type'
                options={flightsFilter.filters.type.options}
                size={'small'}
                fontSize={12}
                padding={0}
                labelMarginTop={8}
                labelShrink={true}
              />
            </Grid>
            {flightsFilter.filters.player.options.length > 0 && (
              <Grid item xs={6}>
                <BasicSelect
                  variant='standard'
                  selected={flightsFilter.filters.team.value}
                  onChange={(value) =>
                    flightsFilter.updateFilterValue('team', value)
                  }
                  label={'Team'}
                  options={flightsFilter.filters.team.options}
                  size={'small'}
                  fontSize={12}
                  padding={0}
                  labelMarginTop={8}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <SearchableDropdown
                fontSize={12}
                items={flightsFilter.filters.player.options.sort(
                  (a, b) => a.number - b.number
                )}
                onSelect={(value) =>
                  flightsFilter.updateFilterValue('player', value)
                }
                filterName={'player'}
                value={flightsFilter.filters.player.value}
                label={'Player'}
                isMultiple={true}
                disableCloseOnSelect={true}
                inputVariant='standard'
                labelShrink={true}
              />
            </Grid>
            {!isSoccer && (
              <Grid item xs={6}>
                <BasicSelect
                  variant='standard'
                  options={flightsFilter.filters.madeTouch.options}
                  onChange={(value) =>
                    flightsFilter.updateFilterValue('madeTouch', value)
                  }
                  label={'Made Touch'}
                  selected={flightsFilter.filters.madeTouch.value}
                  size={'small'}
                  fontSize={12}
                  padding={0}
                  labelMarginTop={8}
                />
              </Grid>
            )}
            {flightEventTypes.isType(
              'kick',
              flightsFilter.filters.type.value
            ) &&
              flightsFilter.filters.kickTypes.options.length > 0 && (
                <Grid item xs={6}>
                  <SearchableDropdown
                    fontSize={12}
                    items={flightsFilter.filters.kickTypes.options}
                    onSelect={(value) =>
                      flightsFilter.updateFilterValue('kickTypes', value)
                    }
                    filterName={'kickTypes'}
                    value={flightsFilter.filters.kickTypes.value}
                    label={'Kick Type'}
                    isMultiple={true}
                    inputVariant='standard'
                    labelShrink={true}
                  />
                </Grid>
              )}
            {flightEventTypes.isType(
              'pass',
              flightsFilter.filters.type.value
            ) &&
              flightsFilter.filters.passTypes.options.length > 0 && (
                <Grid item xs={6}>
                  <SearchableDropdown
                    fontSize={12}
                    items={flightsFilter.filters.passTypes.options}
                    onSelect={(value) =>
                      flightsFilter.updateFilterValue('passTypes', value)
                    }
                    filterName={'passTypes'}
                    value={flightsFilter.filters.passTypes.value}
                    label={'Pass Type'}
                    isMultiple={true}
                    inputVariant='standard'
                    labelShrink={true}
                  />
                </Grid>
              )}
            {flightEventTypes.isType(
              'pass',
              flightsFilter.filters.type.value
            ) &&
              flightsFilter.filters.forward && (
                <Grid item xs={6}>
                  <BasicSelect
                    variant='standard'
                    selected={flightsFilter.filters.forward.value}
                    onChange={(value) =>
                      flightsFilter.updateFilterValue('forward', value)
                    }
                    label={'Forward'}
                    options={flightsFilter.filters.forward.options}
                    size={'small'}
                    fontSize={12}
                    padding={0}
                    labelMarginTop={8}
                  />
                </Grid>
              )}
          </>
        )}
        {eventTypes.isType('aussieRules', eventsFilter.filters.type.value) && (
          <>
            <Grid item xs={6}>
              <BasicSelect
                variant='standard'
                selected={aussieRulesEventsFilter.filters.type.value}
                onChange={(value) =>
                  aussieRulesEventsFilter.updateFilterValue('type', value)
                }
                label='Select Event Type'
                options={aussieRulesEventsFilter.filters.type.options}
                size={'small'}
                fontSize={12}
                padding={0}
                labelMarginTop={8}
                labelShrink={true}
              />
            </Grid>
          </>
        )}
        {eventTypes.isType('game', eventsFilter.filters.type.value) && (
          <>
            <Grid item xs={6}>
              <BasicSelect
                variant='standard'
                selected={gameEventsFilter.filters.type.value}
                onChange={(value) =>
                  gameEventsFilter.updateFilterValue('type', value)
                }
                label='Select Event Type'
                options={gameEventsFilter.filters.type.options}
                size={'small'}
                fontSize={12}
                padding={0}
                labelMarginTop={8}
                labelShrink={true}
              />
            </Grid>
            <Grid item xs={6}>
              <BasicSelect
                variant='standard'
                selected={gameEventsFilter.filters.team.value}
                onChange={(value) =>
                  gameEventsFilter.updateFilterValue('team', value)
                }
                label={'Team'}
                options={gameEventsFilter.filters.team.options}
                size={'small'}
                fontSize={12}
                padding={0}
                labelMarginTop={8}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}
