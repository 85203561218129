import { ReactNode } from 'react'
import { sportableColors } from '../../constants/sportableColors'
import { formatMetrics } from '../metrics/functions'
import { getEmptyGroup } from '../sessions/functions'
import { FormattedSession } from '../sessions/types'
import { addItemToGroup } from '../types'
import { UnitSystem } from '../units/types'
import {
  BibTeam,
  Bibs,
  CreateDrillRequestBody,
  Drill,
  FormattedBibTeam,
  FormattedDrill,
  FormattedDrillPlayer,
  FormattedPlayerBreakdown,
  FormattedPlayerBreakdowns,
  PlayerBib,
  RawPlayerBreakdowns,
  Region
} from './types'
import { timeEventTypes } from '../events/time/data_types'
import { Sport, sportTypes } from '../sports/data_types'
import { timestampToFomattedDate } from '../../utils/helpers'
import { PitchCoordinates } from '../pitches/types'

export type MetricValues<MetricTypeItems> = {
  [k in keyof MetricTypeItems]?: string | number | boolean
}

// Format the bib team for display in the UI
export const formatBibTeam = (
  rawBibTeam: BibTeam,
  bibId: number
): FormattedBibTeam => {
  return {
    rawData: rawBibTeam,
    id: bibId,
    name: rawBibTeam?.name,
    colour: rawBibTeam?.colour
  }
}

// Format the drill for display in the UI
export const formatDrill = (
  drill: Drill,
  formattedSession: FormattedSession
): FormattedDrill => {
  if (!drill) {
    return null
  }
  const drillPlayers = getEmptyGroup<FormattedDrillPlayer>()
  for (const playerId in drill.playerBibs) {
    const player = formattedSession.players.all.map[playerId]

    const bibId = drill.playerBibs[playerId]
    const rawBibTeam = formattedSession.sport.props.features.bibs?.[bibId]
    const bib = formatBibTeam(rawBibTeam, bibId)
    if (player) {
      addItemToGroup(
        drillPlayers,
        { playerId, player, bib },
        playerId,
        'playerId',
        player.nameAndNumber
      )
    }
  }

  const bibs = getEmptyGroup<FormattedBibTeam, number>()

  for (const bibId in formattedSession.sport.props.features.bibs) {
    const rawBibTeam = formattedSession.sport.props.features.bibs?.[bibId]
    const bib = formatBibTeam(rawBibTeam, parseInt(bibId))
    addItemToGroup(bibs, bib, bibId, 'id', bib.name)
  }

  const bibDirection = drill.extraInfo.bibTeamDirection
    ? drill.extraInfo.bibTeamDirection.map((bibId) => bibs.map[bibId])
    : null

  return {
    rawData: drill,
    id: drill.id,
    name: drill.name,
    drillPlayers,
    region: drill.extraInfo.region,

    bibs,
    bibDirection,

    startTime: timestampToFomattedDate(drill.startTime),
    endTime: timestampToFomattedDate(drill.timeEnd),

    isStarted: !!drill.startTime,
    isActive: !!drill.startTime && !drill.timeEnd && formattedSession.live,
    isFinished: !!drill.timeEnd || !formattedSession.live,
    isPending: !drill.startTime && formattedSession.live,

    color: drill.color
  }
}

// Format player breakdown for display in the UI
export const formatPlayerBreakdown = (
  rawPlayerBreakdowns: RawPlayerBreakdowns,
  formattedSession: FormattedSession,
  unitSystem: UnitSystem
): FormattedPlayerBreakdowns => {
  // Map the raw player breakdown metrics to the UI metric keys
  let totalMetrics: MetricValues<
    typeof formattedSession.playerSummaryMetricTypes.items
  > = {}

  // Map the raw player breakdown metrics to the UI metric keys
  if (formattedSession.sport.value === sportTypes.items.soccer.value) {
    totalMetrics = {
      totalPasses: rawPlayerBreakdowns.totals.passes.allPasses.total,
      totalPassCompletion:
        rawPlayerBreakdowns.totals.passes.allPasses.completion.total,
      totalForwardPasses: rawPlayerBreakdowns.totals.passes.forwardPasses.total,
      totalBackwardPasses:
        rawPlayerBreakdowns.totals.passes.backwardPasses.total,
      totalSidewaysPasses:
        rawPlayerBreakdowns.totals.passes.sidewaysPasses.total,
      totalShotsAtGoal: rawPlayerBreakdowns.totals.shooting.total,
      averageRecycleTime:
        rawPlayerBreakdowns.totals.dribbling.averageRecycleTime.value,
      totalGoalsScored: rawPlayerBreakdowns.totals.shooting.totalGoals
    }
  } else if (
    formattedSession.sport.value === sportTypes.items.americanFootball.value
  ) {
    totalMetrics = {
      totalPasses: rawPlayerBreakdowns.totals.passes.allPasses.total,
      totalPassCompletion:
        rawPlayerBreakdowns.totals.passes.allPasses.completion.total,
      averageThrowDistance:
        rawPlayerBreakdowns.totals.passes.allPasses.averageDistance.value,
      averageSpeed:
        rawPlayerBreakdowns.totals.passes.allPasses.averageSpeed.value,
      averageSpin:
        rawPlayerBreakdowns.totals.passes.allPasses.averageSpin.value,
      averageEfficiency:
        rawPlayerBreakdowns.totals.passes.allPasses.averageEfficiency?.value
    }
  }

  // Format the total metrics
  const formattedTotalMetrics = formatMetrics(
    formattedSession.playerSummaryMetricTypes,
    totalMetrics,
    formattedSession,
    unitSystem
  )

  // Format the player breakdown metrics
  const formattedPlayerBreakdowns = getEmptyGroup<FormattedPlayerBreakdown>()

  for (const key in rawPlayerBreakdowns.players) {
    const playerBreakdown = rawPlayerBreakdowns.players[key]

    const formattedPlayer: FormattedPlayerBreakdown = {
      id: key,
      player: formattedSession.players.all.map[key],
      metrics: null
    }

    let playerMetrics: MetricValues<
      typeof formattedSession.playerSummaryMetricTypes.items
    > = {}

    if (formattedSession.sport.value === sportTypes.items.soccer.value) {
      playerMetrics = {
        totalPasses: playerBreakdown.passes.allPasses.total,
        totalPassCompletion: playerBreakdown.passes.allPasses.completion.total,
        totalForwardPasses: playerBreakdown.passes.forwardPasses.total,
        totalBackwardPasses: playerBreakdown.passes.backwardPasses.total,
        totalSidewaysPasses: playerBreakdown.passes.sidewaysPasses.total,
        totalShotsAtGoal: playerBreakdown.shooting.total,
        averageRecycleTime: playerBreakdown.dribbling.averageRecycleTime.value,
        totalGoalsScored: playerBreakdown.shooting.totalGoals
      }
    } else if (
      formattedSession.sport.value === sportTypes.items.americanFootball.value
    ) {
      playerMetrics = {
        totalPasses: playerBreakdown.passes.allPasses.total,
        totalPassCompletion: playerBreakdown.passes.allPasses.completion.total,
        averageThrowDistance:
          playerBreakdown.passes.allPasses.averageDistance.value,
        averageSpeed: playerBreakdown.passes.allPasses.averageSpeed.value,
        averageSpin: playerBreakdown.passes.allPasses.averageSpin.value,
        averageEfficiency:
          playerBreakdown.passes.allPasses.averageEfficiency?.value
      }
    }

    const formattedPlayerMetrics = formatMetrics(
      formattedSession.playerSummaryMetricTypes,
      playerMetrics,
      formattedSession,
      unitSystem
    )

    formattedPlayer.metrics = formattedPlayerMetrics

    addItemToGroup(formattedPlayerBreakdowns, formattedPlayer, key, 'id', key)
  }

  return {
    totals: {
      id: 'totals',
      player: null,
      metrics: formattedTotalMetrics
    },
    players: formattedPlayerBreakdowns
  }
}

// Calculate the style for the drill segment
export const calculateDrillSegmentStyle = (
  sessionDuration: number,
  segment: {
    id: string
    title: string
    start: number
    end: number
    isFinished: boolean
  },
  index: number,
  selectedDrill?: Drill
): React.CSSProperties => {
  const sessionLengthInMinutes = sessionDuration / 60
  const startPercentage = (segment.start / sessionLengthInMinutes) * 100
  const endPercentage = (segment.end / sessionLengthInMinutes) * 100
  const widthPercentage = Math.max(endPercentage - startPercentage, 1) // Ensure minimum width of 1%
  const gap = 4

  return {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'hidden',
    height: `25px`,
    left: `${startPercentage}%`,
    width: `${widthPercentage}%`,
    top: `${index * (25 + gap)}px`,
    backgroundColor:
      selectedDrill?.id === segment.id
        ? sportableColors.colors.sportableBlueLight
        : sportableColors.colors.sportableGreyLight,
    borderRadius: `2px`,
    boxShadow: `0 2px 4px rgba(0,0,0,0.1)`,
    cursor: 'pointer',
    paddingLeft: '4px',
    boxSizing: 'border-box',
    margin: '1px'
  }
}

// Calculate the style for the drill timeline
export const createDrillTimeGrid = (hours: number): ReactNode => {
  const grid = []
  const timeIncrements = 15
  const totalBlocks = (hours * 60) / timeIncrements

  for (let i = 0; i <= totalBlocks; i++) {
    grid.push(
      <div
        key={`grid-${i}`}
        style={{
          position: 'absolute',
          left: `${(i / totalBlocks) * 100}%`,
          top: '0',
          bottom: '0',
          borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
          zIndex: 1
        }}
      ></div>
    )
  }
  return grid
}

// Calculate the style for the time markers in the drill timeline
export const createDrillTimeMarkers = (hours: number): ReactNode => {
  const markers = []
  const totalMinutes = hours * 60
  const increment = hours >= 4 ? 30 : 15

  for (let i = 0; i <= totalMinutes; i += increment) {
    const hours = Math.floor(i / 60)
    const minutes = i % 60
    const timeString = `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`

    markers.push(
      <div
        key={`marker-${i}`}
        style={{
          position: 'absolute',
          left: `${(i / totalMinutes) * 100}%`,
          transform: 'translateX(-50%)',
          zIndex: 2,
          top: '-18px',
          backgroundColor: '#f0f0f0',
          color: '#333',
          borderRadius: '12px',
          padding: '2px 8px',
          fontSize: '12px',
          fontWeight: '500',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}
      >
        {timeString}
      </div>
    )
  }
  return markers
}

export const generateDrillTimeSegments = (
  drills: Drill[],
  sessionStartTime: number
): Array<{
  id: string
  title: string
  start: number
  end: number
  isFinished: boolean
  color: string
  formattedDuration: string
}> => {
  const currentTime = new Date().getTime() / 1000

  return drills
    .filter((drill) => drill.startTime)
    .map((drill, index) => {
      const start = (drill.startTime - sessionStartTime) / 60
      const end = drill.timeEnd
        ? (drill.timeEnd - sessionStartTime) / 60
        : (currentTime - sessionStartTime) / 60
      const title = drill.name

      const duration = drill.timeEnd
        ? drill.timeEnd - drill.startTime
        : currentTime - drill.startTime

      // Calculate hours, minutes, and seconds
      const hours = Math.floor(duration / 3600)
      const minutes = Math.floor((duration % 3600) / 60)
      const seconds = Math.floor(duration % 60)

      // Format the duration as 00:00:00
      const formattedDuration = `${String(hours).padStart(2, '0')}:${String(
        minutes
      ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

      // Check if the drill is finished
      const isFinished = drill.timeEnd && drill.timeEnd < currentTime

      return {
        id: drill.id,
        title,
        start,
        end,
        isFinished,
        color: drill.color,
        formattedDuration
      }
    })
    .sort((a, b) => a.start - b.start)
}

// Function to calculate and update progress
export const updateDrillTimeLineProgressBar = (
  formattedSession: FormattedSession,
  sessionDuration: number,
  setProgress: (progress: number) => void,
  setProgressColor: (color: string) => void
) => {
  const now = new Date().getTime() / 1000 // Current time in seconds
  const elapsedTime = formattedSession.endTime?.unixSeconds
    ? formattedSession.endTime?.unixSeconds -
      formattedSession.startTime.unixSeconds
    : now - formattedSession.startTime.unixSeconds
  const progressPercentage = (elapsedTime / sessionDuration) * 100
  setProgress(progressPercentage > 100 ? 100 : progressPercentage)
  setProgressColor(formattedSession.endTime?.unixSeconds ? 'black' : 'red')
}

export const formatDrillSessionDuration = (duration: number) => {
  duration = Math.floor(duration)
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration % 3600) / 60)
  const seconds = duration % 60

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
    2,
    '0'
  )}:${String(seconds).padStart(2, '0')}`
}

export const getDefaultRegion = (
  coordinates: PitchCoordinates,
  sport: Sport
) => {
  if (!sport.props.pitch?.defaultDrillRegion) {
    return null
  }
  return {
    P1: coordinates[sport.props.pitch?.defaultDrillRegion?.P1],
    P2: coordinates[sport.props.pitch?.defaultDrillRegion?.P2],
    P3: coordinates[sport.props.pitch?.defaultDrillRegion?.P3],
    P4: coordinates[sport.props.pitch?.defaultDrillRegion?.P4],
    P5: coordinates[sport.props.pitch?.defaultDrillRegion?.P5],
    P6: coordinates[sport.props.pitch?.defaultDrillRegion?.P6],
    P7: coordinates[sport.props.pitch?.defaultDrillRegion?.P7],
    P8: coordinates[sport.props.pitch?.defaultDrillRegion?.P8]
  }
}
